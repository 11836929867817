import React, { startTransition } from 'react';
import { navigationBarStyles as classes } from '../styleObjects/NavigationBar';
import { Toolbar, Grid } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { selectedLanguageAtom, isRegionSelectionDialogOpenAtom } from '@atoms/appSettings';
import { CMSLink } from '@components/CMSLink';
import { NavbarMicroContentItem } from '@models/navigationBar';
import { UserMenuDropdown } from '@pages/admin/Navbar/UserMenuDropdown';
import { UserMenuOption } from '@models/adminUserMenu';
import { navbarAtom } from '@atoms/navigation';
import { ClassNames } from '@emotion/react';
import { SelectedLanguage } from '@components/RegionSelection/SelectedLanguage';

interface TopMicroMenuProps {
    userIsAuthenticated: boolean;
    formPopoutOpen: boolean;
}

export function TopMicroMenu({ userIsAuthenticated, formPopoutOpen }: TopMicroMenuProps): JSX.Element {
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const [navbar] = useAtom(navbarAtom);
    const setIsRegionSelection = useSetAtom(isRegionSelectionDialogOpenAtom);

    // eslint-disable-next-line react/no-multi-comp
    const renderComponent = (component: NavbarMicroContentItem): JSX.Element | null => {
        switch (component.type) {
            case 'link':
                return (
                    <CMSLink
                        type='button'
                        href={component.content?.href}
                        variant='text'
                        css={classes.microMenuButtons}
                        target={component?.content?.isTargetToNewTab ? '_blank' : '_self'}
                        title={component?.content?.isTargetToNewTab ? 'This link may open in a new tab' : undefined}
                    >
                        {component.content?.title}
                    </CMSLink>
                );
            default:
                return null;
        }
    };

    return (
        <ClassNames>
            {({ css }) => (
                <Toolbar disableGutters classes={{ root: css(classes.topMicroMenu) }}>
                    <Grid
                        container
                        justifyContent='flex-end'
                        alignItems='center'
                        direction='row'
                        wrap='nowrap'
                        css={classes.topMenuContainer}
                    >
                        {navbar?.showLanguageRegion ? (
                            <button
                                onClick={() => {
                                    startTransition(() => {
                                        setIsRegionSelection(true);
                                    });
                                }}
                                css={classes.microMenuButtons}
                            >
                                <SelectedLanguage languageCode={selectedLanguage} />
                            </button>
                        ) : null}
                        {navbar && navbar.version !== 2 && navbar?.microContent?.length
                            ? navbar.microContent.map((component: NavbarMicroContentItem, index: number) => {
                                return 'type' in component ? (
                                    <Grid key={`${component.type}${index}`}>{renderComponent(component)}</Grid>
                                ) : null;
                            })
                            : null}
                        {userIsAuthenticated && !formPopoutOpen ? (
                            <UserMenuDropdown
                                size='small'
                                dropdownOptions={[
                                    UserMenuOption.Dashboard,
                                    UserMenuOption.History,
                                    UserMenuOption.Logout,
                                ]}
                            />
                        ) : null}
                    </Grid>
                </Toolbar>
            )}
        </ClassNames>
    );
}
